@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.singleFormContainer.mainContainer.singleBody {
  min-height: calc(100vh - 50px); }

.singleFormContainer.mainContainer {
  min-height: calc(100vh - 117px);
  background-color: #1A3374;
  border-color: #1A3374;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 90px;
  padding-right: 75px;
  min-height: -o-calc(100vh - 100px);
  /* opera */
  min-height: -webkit-calc(100vh - 100px);
  /* google, safari */
  min-height: -moz-calc(100vh - 100px);
  /* firefox */ }

.fintup.fintupPrivateContainer.hasOperationBar .singleFormContainer.mainContainer {
  min-height: calc(100vh - 162px); }

.singleFormContainer.mainContainer.double {
  padding-left: 0px;
  padding-right: 0px; }

.singleFormContainer.double .form-container {
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 10px;
  padding-left: 25px;
  padding-bottom: 5px;
  padding-right: 25px; }

.singleFormContainer .form-container {
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 25px;
  padding: 35px; }

@media (max-width: 767px) {
  .singleFormContainer .form-container {
    margin-left: 0px;
    margin-right: 0px; }
  .singleFormContainer.mainContainer.double {
    padding-left: 0px;
    padding-right: 0px;
    min-height: inherit;
    padding-top: 0px;
    padding-bottom: 0px; }
  .singleFormContainer.mainContainer {
    padding-left: 50px;
    padding-right: 40px;
    background-color: transparent;
    border-color: transparent; } }

.singleFormContainer h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 25px; }

.singleFormContainer .formLegend {
  margin-bottom: 15px;
  text-align: center;
  color: #1A3374; }

.singleFormContainer .alert {
  margin-bottom: 5px; }
