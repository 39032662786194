@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupButton .btn-m {
  width: 100px; }

.fintupButton .btn-full {
  width: 100%; }

.fintupButton .btn.btnSelector.login-color.saving {
  background-color: #FFFFFF;
  color: #7FB9C5; }

.fintupButton .btn.btnSelector.saving {
  border-color: #7FB9C5; }

.fintupButton .btn-fintup {
  color: #FFFFFF;
  background-color: #00ABA4;
  border-radius: 25px;
  font-size: 15px;
  font-weight: 700;
  transition: ease all .3s;
  height: 50px; }
  .fintupButton .btn-fintup:hover {
    background-color: black; }
  .fintupButton .btn-fintup.btn-small {
    height: 34px; }
  .fintupButton .btn-fintup.btn-only-icon {
    border-radius: 50px;
    height: 80px;
    padding: 12px;
    width: 80px;
    font-size: 30px; }
  .fintupButton .btn-fintup.principal {
    background-color: #bfdce2;
    color: #1A3374;
    transition: ease all .3s; }
    .fintupButton .btn-fintup.principal:hover {
      background-color: #1A3374;
      color: #bfdce2; }

.fintupButton .btn-bold {
  font-size: 15px;
  font-weight: 800;
  padding-top: 10px;
  padding-bottom: 10px; }

.fintup .btn-l {
  width: 150px; }

.fintup .btn-2l {
  width: 310px; }

.fintup .btn-xl {
  width: 200px; }

.fintup .fintupButton .btn-small {
  font-size: 11px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px; }

.fintup .fintupButton .btnSelector.btn-small {
  font-size: inherit; }

.fintup .fintupButton .alt {
  border-width: 1px;
  border-style: solid;
  border-color: #1A3374;
  background-color: #FFFFFF;
  color: #1A3374; }
  .fintup .fintupButton .alt:hover {
    background-color: #9b9b9b;
    color: white; }

.fintup .fintupButton .btn-2s {
  font-size: 11px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 6px;
  padding-right: 6px;
  width: 190px; }

.fintup .fintupButton .btn-2halfs {
  width: 90px; }

.fintup .fintupButton .btn-xxl {
  width: 260px; }

.fintup .fintupButton .btn-xxxl {
  width: 350px; }

.fintupButton .sk-spinner {
  margin: auto;
  color: #fff; }

.fintupButton.btn-loading .login-color {
  background-color: #758f96 !important;
  border-color: #758f96 !important; }

.fintupButton .btn:focus {
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -0px; }

.fintupButton.btnBold .btn-fintup {
  font-weight: 700; }

.fintupButton.noBorder .btn-fintup {
  border-width: 0px; }

.fintupButton .btn.btnSelector {
  border-radius: 10px;
  border-color: #1A3374;
  border-style: solid;
  border-width: 1px;
  background-color: #1A3374;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px; }

.fintupButton .btn.btnSelector.pensionPlan {
  border-color: #3F96A8;
  background-color: #3F96A8; }

.fintupButto .btn.btnSelector.login-color.pensionPlan {
  background-color: transparent;
  color: #3F96A8; }

.fintupButton .btn.btnSelector.login-color {
  background-color: transparent;
  color: #1A3374; }

.fintupButton .btn.btnStepCard {
  width: 200px;
  border-radius: 25px;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  color: #000000;
  font-size: 20px; }

.fintupButton .btn-fintup.btn.disabled,
.fintupButton .btn-fintup.btn[disabled],
.fintupButton .btn-fintupfieldset[disabled] .btn {
  opacity: 1;
  background-color: #bdbdbd;
  border-color: #bdbdbd; }

.fintup .fintupButton.btn-trash {
  padding: 0px; }
  .fintup .fintupButton.btn-trash button {
    padding: 0px;
    width: 20px;
    height: 20px;
    background: #EF6776;
    display: flex;
    justify-content: center;
    align-items: center; }
    .fintup .fintupButton.btn-trash button img {
      width: 12px;
      height: 12px; }

.fintup .fintupButton.buttonPrev button {
  border-radius: 50px;
  height: 50px;
  padding: 12px;
  width: 50px;
  font-size: 15px;
  background-color: #FFFFFF;
  border: solid 1.5px #e6e6e6;
  color: #e6e6e6;
  margin-right: 10px; }

.fintup .fintupButton.buttonAttention button {
  background-color: #FF5200; }

.fintup .fintupButton.buttonEmail button {
  color: #000000;
  border-radius: 30px;
  background-color: transparent;
  height: 45px;
  width: 150px;
  font-size: 15px;
  border-radius: 30px;
  border: 2px solid #00ABA4;
  font-weight: bold; }
  .fintup .fintupButton.buttonEmail button div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .fintup .fintupButton.buttonEmail button div img {
      height: 18px;
      margin-right: 15px; }
    .fintup .fintupButton.buttonEmail button div p {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0px; }

.fintupButton.buttonPrevMiniTest button {
  border-radius: 50px !important;
  height: 50px !important;
  padding: 12px !important;
  width: 50px !important;
  font-size: 15px !important;
  background-color: #FFFFFF !important;
  border: solid 1.5px #979797 !important;
  color: #979797 !important;
  margin-right: 10px !important; }
  .fintupButton.buttonPrevMiniTest button span::before {
    margin-right: 3px; }

.fintupButton.buttonWhiteAndGreen button {
  border-radius: 50px !important;
  height: 35px !important;
  padding: 5px 10px !important;
  font-size: 13px !important;
  background-color: #FFFFFF !important;
  border: 1px solid #00ABA4 !important;
  color: #000000 !important; }
  .fintupButton.buttonWhiteAndGreen button span::before {
    margin-right: 3px; }

/* Extra small devices (767px and down) */
@media (max-width: 767px) {
  .fintup .fintupButton .btn-small {
    font-size: 12px; }
  .fintupButton .btn.btnSelector {
    font-size: 17px; } }
