$marginTopList: 100 50 30 20 25 15 10;
$marginTopImportantList: 100 150 170;
$marginBottomList: 10 20 25;
$marginRightList: 5 10 20;
$marginLeftList: 5 10 15 20;
$paddingLeftList: 10 15 20 25 30;
$paddingRightList: 10 15 20 25 30;
$paddingTopList: 0 5 15 30;

$tileTextPadding: 15px;
$stepFormSeparation: 15px;
$stepFormBlock: 20px;

$toolsUnderlineWidth: 2px;

$z-index-slider-ball: 15;
$z-index-bar: 20;
$z-index-input-control: 25;

$desktop-header-size: 72px;
$desktop-header-new-size: 80px;