@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.modal.full {
  overflow-y: auto; }
  .modal.full .modal-header, .modal.full .modal-body {
    padding: 20px; }
  .modal.full .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto; }
    .modal.full .modal-dialog .modal-content {
      margin-top: 0px;
      overflow-y: auto;
      min-height: 100%;
      height: auto; }
  @media (min-width: 768px) {
    .modal.full .modal-dialog {
      width: 80%;
      height: auto;
      margin: 30px auto;
      box-shadow: 0 0 4px 0 #b5b5b5;
      border: solid 0 #FFFFFF;
      border-radius: 20px; }
      .modal.full .modal-dialog .modal-header, .modal.full .modal-dialog .modal-body {
        padding: 25px 50px; }
      .modal.full .modal-dialog .modal-content {
        min-height: auto;
        border: 0px;
        border-radius: 20px; } }
  .modal.full.new .row {
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px; }
    .modal.full.new .row:first-child {
      margin-top: 20px; }
  @media (min-width: 768px) {
    .modal.full.new .modal-dialog {
      width: 35%;
      margin: 30px auto;
      min-width: 620px; } }

.modal-video-close-btn:after {
  background: #1A3374; }

.modal .modal-dialog {
  z-index: 100001; }

.modal.modal-block {
  display: block; }

.modal .modal-dialog {
  z-index: 100001; }

.modal .modal-content {
  z-index: 100001;
  margin-top: 65px; }

.modal.absoluteModal {
  position: absolute;
  overflow: initial; }
