@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #00ABA4;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #00ABA4;
      padding: 0px 10px; }

.selectLabel .required-label {
  color: #FB4D6D;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px; }

.selectLabel label {
  margin-bottom: 10px; }

.selectLabel label.htmlLabel {
  font-weight: inherit; }

.selectLabel .form-group.row label.rightLabel {
  margin-top: 0px; }

.selectLabel .form-group.row label.rightLabel.dividerLabel {
  border-left-color: silver;
  border-left-width: 1px;
  border-left-style: solid; }

.selectLabel .Select.is-disabled div.Select-control {
  background-color: #eee; }

.selectLabel .Select.is-focused:not(.is-open) > .Select-control {
  border-color: #1A3374; }

.selectLabel .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.selectLabel .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #1A3374; }

.selectLabel.pensionPlan .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel.pensionPlan .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel.pensionPlan .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.selectLabel.pensionPlan .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus
.selectLabel.pensionPlan .Select--multi .Select-value,
.selectLabel.pensionPlan .Select--multi a.Select-value-label,
.selectLabel.pensionPlan.innerInput .Select-arrow-zone {
  color: #3F96A8; }

.selectLabel.saving .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel.saving .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel.saving .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.selectLabel.saving .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus
.selectLabel.saving .Select--multi .Select-value,
.selectLabel.saving .Select--multi a.Select-value-label,
.selectLabel.saving.innerInput .Select-arrow-zone {
  color: #7FB9C5; }

.selectLabel.pensionPlan.innerInput .labelInputContainer {
  background-color: #3F96A8; }

.selectLabel.saving.innerInput .labelInputContainer {
  background-color: #7FB9C5; }

.selectLabel.pensionPlan.innerInput .Select-control {
  border-color: #3F96A8;
  border: 1px solid #3F96A8;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #3F96A8;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #3F96A8; }

.selectLabel.saving.innerInput .Select-control {
  border-color: #7FB9C5;
  border: 1px solid #7FB9C5;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #7FB9C5;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #7FB9C5; }

.selectLabel .Select--multi .Select-value {
  color: #1A3374; }

.selectLabel .Select--multi a.Select-value-label {
  color: #1A3374; }

.selectLabel.innerInput .labelInputContainer {
  position: absolute;
  z-index: 2;
  background-color: #1A3374;
  color: #FFFFFF;
  font-weight: inherit;
  top: 2px;
  left: 2px;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px; }

.selectLabel.innerInput.innerLabelWidth90 .labelInputContainer {
  width: 90px; }

.selectLabel.innerInput.innerLabelWidth90 .Select-placeholder, .selectLabel.innerInput.innerLabelWidth90 .Select-value-label, .selectLabel.innerInput.innerLabelWidth90 .Select-input {
  padding-left: 95px; }

.selectLabel.innerInput .Select-menu-outer {
  z-index: 10; }

.selectLabel.innerInput .Select-arrow-zone {
  color: #1A3374; }

.selectLabel.innerInput .Select-control {
  background-color: #FFFFFF;
  border-color: #1A3374;
  border-radius: 0px;
  border: 1px solid #1A3374;
  color: #000000;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: 0;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #1A3374;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #1A3374; }

.selectLabel.theme-tools {
  font-size: 25px; }
  .selectLabel.theme-tools .Select-arrow-zone {
    color: #1A3374; }
  .selectLabel.theme-tools .Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: none; }
  .selectLabel.theme-tools .Select-control {
    background-color: #FFFFFF;
    border-radius: 0px;
    border: 0px solid #1A3374;
    border-bottom: 2px solid #1A3374;
    color: #000000;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
    outline: 0; }

.selectLabel.theme-new {
  font-family: 'Lato';
  font-size: 30px; }
  .selectLabel.theme-new .Select-arrow-zone {
    color: #00ABA4; }
  .selectLabel.theme-new .Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    border-color: #00ABA4; }
  .selectLabel.theme-new .Select-control {
    background-color: #FFFFFF;
    border-radius: 0px;
    border: 0px solid #00ABA4;
    border-bottom: 2px solid #00ABA4;
    color: #000000;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    outline: none;
    overflow: hidden;
    width: 100%;
    outline: 0; }
    .selectLabel.theme-new .Select-control .Select-multi-value-wrapper {
      height: 100%;
      display: block; }
    .selectLabel.theme-new .Select-control .Select-placeholder {
      color: #979797;
      font-size: 16px;
      line-height: inherit;
      padding-left: 0;
      padding-top: 5px; }
    .selectLabel.theme-new .Select-control .Select-value {
      line-height: inherit;
      padding-left: 0;
      padding-top: 5px; }
      .selectLabel.theme-new .Select-control .Select-value .Select-value-label {
        color: #000000 !important; }
    .selectLabel.theme-new .Select-control .Select-input {
      height: auto;
      padding-left: 0px; }
      .selectLabel.theme-new .Select-control .Select-input > input {
        position: relative;
        padding: 0px;
        height: auto; }
  .selectLabel.theme-new.is-error .Select-arrow-zone {
    color: #FB4D6D; }
  .selectLabel.theme-new.is-error .Select-control {
    border-bottom: 2px solid #FB4D6D;
    /*&::placeholder, &::-webkit-input-placeholder {
          color: $color-cross-element-minitest;
        }*/ }
  .selectLabel.theme-new.is-error .errorText {
    color: #FB4D6D;
    font-size: 14px;
    display: block;
    margin-top: 5px;
    text-align: left; }
  .selectLabel.theme-new .Select-menu-outer {
    border: 0;
    background-color: #FFFFFF;
    border-top-color: #e6e6e6;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: 0px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100;
    -webkit-overflow-scrolling: touch; }
  .selectLabel.theme-new .Select-menu {
    max-height: 100%;
    max-height: 400px;
    overflow-y: auto;
    background: #FFFFFF; }
  .selectLabel.theme-new .Select-option {
    margin-top: 15px;
    border-radius: 20px;
    border: solid 1px #e6e6e6;
    box-sizing: border-box;
    box-shadow: 2px 2px 5px 0 #e6e6e6;
    font-weight: lighter;
    background-color: #FFFFFF;
    color: #000000;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
    padding-left: 20px; }
    .selectLabel.theme-new .Select-option.is-selected, .selectLabel.theme-new .Select-option:hover {
      border: solid 2px #00ABA4;
      font-weight: 500;
      /* Fallback color for IE 8 */
      background-color: #007eff0a;
      color: #000000; }
    .selectLabel.theme-new .Select-option.is-selected {
      font-weight: bold; }
    .selectLabel.theme-new .Select-option.is-focused {
      background-color: #ebf5ff;
      /* Fallback color for IE 8 */
      background-color: #007eff0a;
      color: #000000; }
    .selectLabel.theme-new .Select-option.is-disabled {
      color: #b5b5b5;
      cursor: default; }
  .selectLabel.theme-new .Select-noresults {
    box-sizing: border-box;
    color: #000000;
    cursor: default;
    display: block;
    padding: 8px 10px; }

/*Small and down*/
@media (max-width: 767px) {
  .selectLabel.theme-tools {
    font-size: 21px; }
  .selectLabel.innerInput .labelInputContainer {
    padding-top: 8px;
    padding-bottom: 9px; } }
