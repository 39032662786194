.suitabilitySteps{
  /* margin-left: -35px; */
  margin-bottom: 15px;
  margin-top: 20px;
}

.suitabilitySteps .suitabilityStep.notActive {
  background-color: #7fb9c5;
  color: #3f96a8;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 700;
}

/* .suitabilitySteps .suitabilityStep.previousStep {
  cursor: pointer;
} */

.suitabilitySteps .suitabilityStep.active {
  background-color: #fff;
  color: #3f96a8;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 50px;
  font-weight: 700;
}
