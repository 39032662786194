.fintup {
  &.safari {
    .inputControl {
      .form-control {
        height: 36px;
      }
    }

    .oneMillionView {
      .targetBlock {
        &__first {
          width: 40rem;
          &__blockRetirementAge{
            width: 23rem;
          }
        }

        &__second {
          width: 42rem;
          &__blockRetirementAmount{
            width: 24rem;
          }
        }
      }
    }
  }


  &.ios {
    .oneMillionView {
      .targetBlock {
        &__first {
          width: 31rem;
        }
        &__second {
          width: 33rem;
        }
      }
    }
  }
}