.fintupCheckboxLabel {
  margin-bottom: 0px;
}

.checkboxGroup.horizontal div[class^="col-sm"]{
  padding-left: 0px;
}

.checkboxGroup.horizontal .fintupCheckbox:first-child { 
  margin-top: 0px;
}