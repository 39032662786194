@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.contractGeneralView {
  /*
  * Mobile
  */ }
  .contractGeneralView .multipleFormContainer {
    padding: 0;
    min-height: auto;
    border: 0;
    background-color: #FFFFFF; }
  .contractGeneralView .step-container {
    margin-top: 0px;
    border-radius: 0;
    box-shadow: none;
    border: 0; }
  .contractGeneralView .content {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding-top: 15px !important; }
    .contractGeneralView .content .contentData {
      width: 40%;
      margin: auto;
      text-align: left; }
      .contractGeneralView .content .contentData .row {
        margin-bottom: 15px; }
        .contractGeneralView .content .contentData .row .titleLabel {
          font-size: 18px !important; }
          .contractGeneralView .content .contentData .row .titleLabel img {
            width: 13px; }
        .contractGeneralView .content .contentData .row .form-group {
          margin-top: 10px;
          font-size: 18px !important; }
        .contractGeneralView .content .contentData .row label {
          font-size: 18px !important; }
        .contractGeneralView .content .contentData .row .react-date-picker__wrapper {
          margin-top: 10px; }
        .contractGeneralView .content .contentData .row .radioGroup {
          margin-top: 15px;
          margin-bottom: 15px; }
          .contractGeneralView .content .contentData .row .radioGroup .radioButton {
            margin: 0px; }
        .contractGeneralView .content .contentData .row .beneficiarie {
          margin-top: 10px; }
          .contractGeneralView .content .contentData .row .beneficiarie .btn-trash {
            display: flex;
            justify-content: flex-end; }
    .contractGeneralView .content .buttonAttention {
      margin-top: 20px;
      margin-bottom: 20px; }
    .contractGeneralView .content .buttonsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 1%;
      margin-right: 1%; }
  @media (max-width: 575.98px) {
    .contractGeneralView .content {
      /*.miniTestTabs{
        width: 100%;
      }*/ }
      .contractGeneralView .content .contentData {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px; }
    .contractGeneralView .multipleFormContainer .step-container {
      height: 100%; } }
  @media (max-width: 1250px) {
    .contractGeneralView {
      margin-top: 40px; }
      .contractGeneralView .content {
        padding-left: 0px;
        padding-right: 0px; } }
