@import './_colors.scss';
@import './_helpers.scss';

/**
* New styles of components
*/

*.theme-new{
    font-family: 'Lato' !important;
    font-size: $font-size-large !important;  

    label, .form-control{
      font-family: 'Lato' !important;
      font-size: $font-size-large !important;  
    }

    .react-date-picker__wrapper{
      font-family: 'Lato';
      //font-size: $font-size-xextrabig;
      border: none;
      border-bottom: 2px solid $action;
      box-shadow: none;
      min-width: 180px;
      margin-bottom: 15px;
      
      .react-date-picker__inputGroup{
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input{
        &::placeholder, &::-webkit-input-placeholder {
          font-weight: 400;
          color: $color-placeholder;
        }
      }

      .react-date-picker__inputGroup__divider{
        font-size: 30px;
        font-weight: 500;
        color: $action;
        padding: 0px 10px;
      }
    }
}