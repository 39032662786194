@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.limitSize {
  max-width: 1200px;
  margin: auto !important; }

.miniTestStep6 .contentFull {
  max-width: 100%;
  width: 100%;
  padding: 0; }
  .miniTestStep6 .contentFull .miniTestTabs {
    max-width: 1200px;
    width: 95%;
    margin: auto; }

@media (min-width: 768px) {
  .miniTestStep6 .content .miniTestTabs {
    width: 50%; } }

.miniTestProductLanding {
  /*
  * General styles
  */
  /*
  * END General styles
  */
  /** 
  * Mobile phone - tablet
  */ }
  .miniTestProductLanding .row {
    padding-top: 25px !important; }
  .miniTestProductLanding h2, .miniTestProductLanding h3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
    text-align: center; }
  .miniTestProductLanding p {
    margin-bottom: 0 !important; }
  .miniTestProductLanding h3.subtitle {
    margin-bottom: 50px !important;
    font-size: 25px !important; }
  .miniTestProductLanding .buttonClass {
    font-size: 15px;
    font-weight: bold;
    text-transform: none;
    letter-spacing: 0.4px; }
  .miniTestProductLanding .icon-wrapper {
    letter-spacing: 0.4px;
    font-size: 20px;
    display: inline-block;
    width: 25px;
    letter-spacing: 0.7px;
    margin-right: 7px; }
    .miniTestProductLanding .icon-wrapper .fa {
      font-size: 21px; }
    .miniTestProductLanding .icon-wrapper .fa-check {
      color: #00ABA4; }
    .miniTestProductLanding .icon-wrapper .fa-times {
      color: #EF6776; }
  .miniTestProductLanding .text {
    text-align: justify;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 30px;
    font-weight: 300; }
    .miniTestProductLanding .text strong {
      font-size: 21px;
      display: block; }
  .miniTestProductLanding table.tableModal {
    margin: auto;
    margin-top: 0px; }
    .miniTestProductLanding table.tableModal tr th {
      color: #FFFFFF;
      background-color: #1A3374; }
    .miniTestProductLanding table.tableModal tr td, .miniTestProductLanding table.tableModal tr th {
      text-align: center;
      padding: 0px;
      padding-left: 30px;
      padding-right: 30px; }
  .miniTestProductLanding .col-list-table {
    padding: 0px !important; }
  .miniTestProductLanding .recommendation {
    padding-top: 0px !important;
    height: 75px;
    background: linear-gradient(rgba(223, 237, 240, 0.5), rgba(223, 237, 240, 0.5));
    background-position: 0px 38%;
    background-size: cover;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s; }
    .miniTestProductLanding .recommendation .limitSize {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .miniTestProductLanding .recommendation .limitSize .investTypeContainer {
        text-align: left; }
        .miniTestProductLanding .recommendation .limitSize .investTypeContainer h2 {
          font-size: 25px;
          text-align: center;
          letter-spacing: 0.4px; }
        .miniTestProductLanding .recommendation .limitSize .investTypeContainer p {
          font-size: 16px; }
        .miniTestProductLanding .recommendation .limitSize .investTypeContainer .buttonContainer {
          text-align: left; }
          .miniTestProductLanding .recommendation .limitSize .investTypeContainer .buttonContainer .buttonClass {
            margin-top: 15px; }
      .miniTestProductLanding .recommendation .limitSize .profitabilityContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /*
        TODO: remove when profitability values appear
        div:first-child{
          margin-right: 50px;
        }*/ }
        .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div h3 {
          text-align: center;
          color: #1A3374;
          font-size: 50px;
          margin-top: 0; }
        .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div p {
          font-size: 16px;
          letter-spacing: 0.4px; }
  .miniTestProductLanding .videoText {
    width: 80%; }
    .miniTestProductLanding .videoText .videoContainer {
      height: 450px;
      margin-bottom: 20px; }
  .miniTestProductLanding .external {
    margin-top: 50px;
    margin-bottom: 50px; }
    .miniTestProductLanding .external .buttonClass {
      margin-bottom: 20px; }
    .miniTestProductLanding .external a {
      color: #1A3374;
      text-decoration: underline; }
  .miniTestProductLanding .information {
    padding-top: 0px !important; }
    .miniTestProductLanding .information h3.subtitle {
      padding: 15px; }
    .miniTestProductLanding .information .informationContainer .navigationSlider {
      display: flex;
      justify-content: center;
      align-items: center; }
      .miniTestProductLanding .information .informationContainer .navigationSlider .buttonSlideContainer {
        background: transparent;
        border: 0; }
        .miniTestProductLanding .information .informationContainer .navigationSlider .buttonSlideContainer .buttonSlide {
          width: 40px;
          height: 40px;
          background: transparent;
          border: 1px solid #00ABA4;
          color: #00ABA4;
          font-size: 18px;
          padding: 8px; }
      .miniTestProductLanding .information .informationContainer .navigationSlider ul {
        align-items: center !important; }
      .miniTestProductLanding .information .informationContainer .navigationSlider .titleSlide {
        font-size: 25px;
        margin-top: 10px; }
      .miniTestProductLanding .information .informationContainer .navigationSlider .subtitleSlide {
        font-size: 20px;
        color: #1A3374;
        margin-bottom: 10px; }
    .miniTestProductLanding .information .informationContainer .dotReferencesSlide {
      margin: 14px;
      margin-top: 8px; }
      .miniTestProductLanding .information .informationContainer .dotReferencesSlide button {
        height: 10px;
        width: 10px;
        background: transparent;
        border-radius: 50%;
        border: solid 1px #979797;
        padding: 0;
        margin-right: 14px; }
        .miniTestProductLanding .information .informationContainer .dotReferencesSlide button:last-child {
          margin-right: 0px; }
      .miniTestProductLanding .information .informationContainer .dotReferencesSlide button.carousel__dot--selected {
        border: 1px solid #00ABA4;
        background: #00ABA4; }
    .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide {
      height: auto; }
      .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide {
        padding-right: 30px; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide img.full {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          margin-bottom: 20px; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide p {
          margin-bottom: 20px; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .textFooterSlider {
          text-align: center;
          font-style: italic;
          font-size: 16px; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide ul {
          list-style-type: disc; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements {
          justify-content: space-around;
          margin-top: 30px;
          margin-bottom: 30px; }
          .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 100px; }
            .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements div img {
              width: 60px;
              margin-bottom: 10px; }
            .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements div p:first-child {
              font-size: 16px; }
            .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements div p:last-child {
              margin-top: 10px;
              font-size: 18px; }
    .miniTestProductLanding .information .advantagesContainer div {
      width: 100%;
      margin: auto;
      padding: 10px 10px 10px;
      border-radius: 35px; }
      .miniTestProductLanding .information .advantagesContainer div h4 {
        font-weight: bold;
        font-size: 25px;
        color: #1A3374;
        margin-top: 0px;
        margin-bottom: 20px; }
      .miniTestProductLanding .information .advantagesContainer div .text {
        text-align: left; }
      .miniTestProductLanding .information .advantagesContainer div p {
        text-align: left;
        display: flex;
        line-height: 25px !important;
        padding-bottom: 10px; }
    .miniTestProductLanding .information .advantagesContainer .hrefReviews img {
      max-width: 100%;
      padding: 0px 10px;
      height: 27px; }
  .miniTestProductLanding .comparator {
    padding: 15px;
    margin: auto;
    text-align: left;
    padding-bottom: 25px; }
    .miniTestProductLanding .comparator h3.subtitle {
      margin-bottom: 25px !important; }
    .miniTestProductLanding .comparator .comparatorContainer {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      justify-content: space-between;
      margin-bottom: 25px; }
      .miniTestProductLanding .comparator .comparatorContainer .comparatorElement {
        margin-top: 25px;
        width: 250px;
        height: 300px;
        box-shadow: 2px 2px 5px 0 #e6e6e6;
        border: solid 1px #e6e6e6;
        background-color: #FFFFFF;
        border-radius: 30px;
        border-width: 2px;
        transition: background 0.3s, border 2s, border-radius 2s, box-shadow 2s;
        padding: 20px; }
        .miniTestProductLanding .comparator .comparatorContainer .comparatorElement.active {
          background-color: #EFFCFF;
          border: #1A3374 solid 2px;
          box-shadow: 0px 0px 25px 0px #e6e6e6; }
        .miniTestProductLanding .comparator .comparatorContainer .comparatorElement h4 {
          color: #1A3374;
          font-size: 20px; }
        .miniTestProductLanding .comparator .comparatorContainer .comparatorElement p {
          display: flex;
          align-items: flex-start; }
          .miniTestProductLanding .comparator .comparatorContainer .comparatorElement p span {
            text-align: left; }
    .miniTestProductLanding .comparator .text {
      width: 80%;
      margin: auto; }
  .miniTestProductLanding .doubts {
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 280px;
    background: linear-gradient(rgba(223, 237, 240, 0.5), rgba(223, 237, 240, 0.5)), url(https://fintup.com/wp-content/uploads/2019/04/fintup-testInversor-squashed.jpg);
    background-position: top center;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; }
    .miniTestProductLanding .doubts p {
      font-weight: bold;
      font-size: 25px;
      color: #000000;
      letter-spacing: 0.4px;
      line-height: 1.33; }
    .miniTestProductLanding .doubts .buttonClass {
      margin-top: 30px; }
  .miniTestProductLanding .videoWidget {
    z-index: 99;
    position: fixed;
    right: 35px;
    bottom: 60px;
    width: 100%;
    max-width: 336px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer; }
    .miniTestProductLanding .videoWidget .tooltip {
      position: relative;
      box-shadow: 2px 2px 5px 0 #e6e6e6;
      background-color: #1A3374;
      border-radius: 30px;
      padding: 8px 24px;
      opacity: .8;
      transform: none; }
      .miniTestProductLanding .videoWidget .tooltip .text {
        color: #FFFFFF;
        text-align: right;
        line-height: 25px; }
      .miniTestProductLanding .videoWidget .tooltip:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: calc(100% - 1px);
        transform: translate3d(0, -50%, 0);
        width: 0;
        height: 0;
        margin-left: -3px;
        border-style: solid;
        border-width: 12px 0 12px 8px;
        border-color: transparent transparent transparent #1A3374; }
    .miniTestProductLanding .videoWidget .fabAvatar {
      position: relative;
      margin-left: 15px; }
      .miniTestProductLanding .videoWidget .fabAvatar .managerImage .imageFrame {
        height: auto;
        width: auto;
        align-items: center;
        display: flex;
        justify-content: center;
        background: transparent; }
        .miniTestProductLanding .videoWidget .fabAvatar .managerImage .imageFrame img.mainImage {
          width: 75px;
          height: 75px;
          border-radius: 75px;
          vertical-align: middle;
          border: 0; }
    .miniTestProductLanding .videoWidget .dismissVideoWidget {
      border: 0;
      padding: 0;
      background: transparent;
      position: absolute;
      top: -10px;
      right: -15px;
      font-size: 25px; }
  .miniTestProductLanding .modalBodyReport {
    width: 50%;
    margin: auto; }
    .miniTestProductLanding .modalBodyReport .radioButton {
      margin-top: 20px;
      margin-bottom: 15px !important;
      text-align: left; }
  @media (max-width: 767px) {
    .miniTestProductLanding {
      /*
    * General styles
    */
      /*
    * END General styles
    */ }
      .miniTestProductLanding .recommendation {
        padding: 25px !important;
        height: auto; }
        .miniTestProductLanding .recommendation .limitSize .investTypeContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column; }
        .miniTestProductLanding .recommendation .limitSize .profitabilityContainer {
          padding: 0;
          justify-content: space-around; }
          .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div:first-child {
            margin-right: 0px; }
          .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div h3 {
            font-size: 40px; }
          .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div p {
            font-size: 14px; }
      .miniTestProductLanding .videoText {
        width: 100%;
        padding: 15px; }
        .miniTestProductLanding .videoText .videoContainer {
          height: 450px;
          margin-bottom: 20px; }
      .miniTestProductLanding .information h3.subtitle {
        padding: 15px; }
      .miniTestProductLanding .information .informationContainer .contentGeneralSlider {
        margin-bottom: 30px; }
        .miniTestProductLanding .information .informationContainer .contentGeneralSlider .contentSlide {
          padding-right: 0px !important; }
      .miniTestProductLanding .information .advantagesContainer {
        padding: 0; }
        .miniTestProductLanding .information .advantagesContainer div {
          padding-top: 50px;
          width: 100%;
          border-radius: 0; }
      .miniTestProductLanding .comparator .comparatorContainer {
        justify-content: center; }
        .miniTestProductLanding .comparator .comparatorContainer .comparatorElement {
          margin: 10px; }
      .miniTestProductLanding .comparator div.text {
        width: 100%; }
      .miniTestProductLanding .modalBodyReport {
        width: 100%; } }
