@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.icon-background4 {
  color: #FFFFFF; }

.icon-background6 {
  color: #f2f2f2; }

.userInfoIcon {
  color: #a2a2a2; }

.userInfoText p {
  margin-bottom: 0px;
  color: #666666; }

.section-separator:after {
  content: ' ';
  display: block;
  float: left;
  background: #ADADAD;
  margin: 0 10px;
  height: 34px;
  width: 1px; }

.navPrivate {
  height: 72px;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: #1A3374;
  padding-left: 20px;
  padding-right: 20px;
  color: #FFFFFF;
  font-size: 18px; }
  .navPrivate .globalPositionLink {
    cursor: pointer; }
  .navPrivate .hello {
    margin-top: 25px; }
  .navPrivate .logout {
    margin-top: 23px;
    margin-right: 15px;
    margin-left: 0px;
    cursor: pointer; }
  .navPrivate .sep {
    margin-top: 12px; }
    .navPrivate .sep img {
      height: 40px;
      margin-top: 5px; }
  .navPrivate .notificationContainer {
    margin-top: 5px;
    margin-right: 20px; }
  .navPrivate .default-logo {
    margin-top: 10px; }
  .navPrivate .centralContainer {
    display: flex;
    align-items: center;
    justify-content: center; }
    .navPrivate .centralContainer .centralContent {
      margin-top: 25px;
      margin-left: 15px;
      margin-right: 15px;
      cursor: pointer; }
      .navPrivate .centralContainer .centralContent.active {
        font-weight: bold; }
  .navPrivate .inviteContainer {
    margin-top: 25px;
    margin-right: 15px;
    cursor: pointer; }
    .navPrivate .inviteContainer.active {
      font-weight: bold; }
  .navPrivate .profileContainer {
    cursor: pointer; }
    .navPrivate .profileContainer img {
      margin-top: 25px;
      margin-right: 25px;
      margin-left: 15px; }

@media (max-width: 767px) {
  .navPrivate {
    height: 115px; }
    .navPrivate .logout {
      margin-right: 0px; }
    .navPrivate .centralContainer .centralContent {
      margin-top: 15px; } }
