@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupHeaderContainer .multipleFormContainer {
  min-height: calc(100vh - 117px);
  min-height: -o-calc(100vh - 117px);
  /* opera */
  min-height: -webkit-calc(100vh - 117px);
  /* google, safari */
  min-height: -moz-calc(100vh - 117px);
  /* firefox */ }

.fintupFullContainer .multipleFormContainer {
  min-height: calc(100vh - 0px);
  min-height: -o-calc(100vh - 0px);
  /* opera */
  min-height: -webkit-calc(100vh - 0px);
  /* google, safari */
  min-height: -moz-calc(100vh - 0px);
  /* firefox */ }

.fintupFullContainer.hasOperationBar .multipleFormContainer {
  padding-bottom: 100px; }

.multipleFormContainer {
  background-color: #1A3374;
  border-color: #1A3374;
  padding-top: 1px;
  padding-bottom: 60px;
  padding-left: 235px;
  padding-right: 235px; }
  .multipleFormContainer .mainContainer__steps {
    width: 100%;
    display: flex;
    justify-content: center; }
    .multipleFormContainer .mainContainer__steps .suitabilitySteps {
      padding: 0 10%;
      display: flex;
      flex: 1;
      justify-content: space-between; }
      @media (min-width: 767px) {
        .multipleFormContainer .mainContainer__steps .suitabilitySteps {
          padding: 0 20%; } }
      .multipleFormContainer .mainContainer__steps .suitabilitySteps .suitabilityStep.notActive, .multipleFormContainer .mainContainer__steps .suitabilitySteps .suitabilityStep.active {
        margin-left: 0; }
      .multipleFormContainer .mainContainer__steps .suitabilitySteps .clearAll {
        display: none; }

.multipleFormContainer .step-container {
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  margin-top: 25px; }

.multipleFormContainer h2 img {
  cursor: pointer; }

.multipleFormContainer .multipleFormContainerTitle {
  margin-bottom: 0px; }

.multipleFormContainerWidget h2.multipleFormContainerTitle {
  width: 100%;
  background-color: #3f96a8;
  margin-top: 0px;
  margin-bottom: 15px;
  padding-top: 20px;
  padding-bottom: 20px; }

@media (max-width: 1250px) {
  .multipleFormContainer {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 1px; }
  .multipleFormContainer .mainContainer h2 {
    text-align: center; }
  .multipleFormContainer .step-container {
    margin-left: 0px;
    margin-right: 0px; }
  .multipleFormContainer .content {
    padding-left: 35px;
    padding-right: 35px; }
  .multipleFormContainer .content h2 {
    margin-top: 20px; }
  .multipleFormContainer .buttonContainer {
    text-align: center;
    margin-right: 0px; } }
