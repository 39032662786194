@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/*
* Visual footer always at the bottom 
*/
.fintup.fintupFullContainer {
  position: relative;
  min-height: 100vh; }
  .fintup.fintupFullContainer .appContent {
    padding-bottom: 50px; }
  .fintup.fintupFullContainer .footer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%; }
  @media (max-width: 767px) {
    .fintup.fintupFullContainer .appContent {
      padding-bottom: 90px; } }
  @media (min-width: 768px) {
    .fintup.fintupFullContainer .footer-bottom .container {
      width: 100%; } }

.miniTestView {
  /*
  * Mobile
  */ }
  .miniTestView .rtf {
    bottom: 65px !important; }
  .miniTestView .multipleFormContainer {
    padding: 0;
    min-height: auto;
    border: 0;
    background-color: #FFFFFF; }
    .miniTestView .multipleFormContainer .multipleFormContainerTitle {
      margin-left: 0px;
      margin-top: 0px;
      padding-left: 25px;
      width: 100%;
      height: 80px;
      text-align: left;
      display: flex;
      align-items: center;
      background: #1A3374; }
    .miniTestView .multipleFormContainer .step-container {
      margin-top: 0px;
      border-radius: 0;
      box-shadow: none;
      border: 0; }
  .miniTestView .content {
    max-width: 1200px;
    font-family: 'Lato';
    width: 100%;
    margin: auto;
    padding-top: 15px !important;
    /*
    * Tabs
    */
    /*
    * Customize style FintupSlider component
    */ }
    .miniTestView .content .m-auto {
      margin: auto; }
    .miniTestView .content .text-center {
      text-align: center !important; }
    .miniTestView .content .marked {
      color: #1A3374;
      font-weight: bold; }
    .miniTestView .content .row-no-padding [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .miniTestView .content .titleMini {
      font-size: 14px; }
    .miniTestView .content .titleMiniSmall {
      font-size: 8px; }
    .miniTestView .content .contentTabs {
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 15px;
      z-index: 1000; }
      .miniTestView .content .contentTabs .miniTestTabs {
        height: 55px;
        margin: auto;
        border-radius: 20px;
        box-shadow: 0 0 4px 0 #b5b5b5;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: solid 0 #FFFFFF;
        background: #FFFFFF;
        /*
        * Title
        */
        /*
        * Subtitle
        */ }
        .miniTestView .content .contentTabs .miniTestTabs .miniTestTabsElement {
          cursor: pointer;
          padding: 4px; }
        .miniTestView .content .contentTabs .miniTestTabs > div {
          width: 100%;
          border-left: dotted 0.5px #9b9b9b; }
          .miniTestView .content .contentTabs .miniTestTabs > div:first-child {
            border-left: none; }
        .miniTestView .content .contentTabs .miniTestTabs p {
          font-weight: normal;
          margin-bottom: 0px; }
        .miniTestView .content .contentTabs .miniTestTabs p:first-child {
          font-size: 14px;
          letter-spacing: 0.4px;
          color: #000000; }
        .miniTestView .content .contentTabs .miniTestTabs p:not(:first-child) {
          font-size: 8px;
          color: #1A3374; }
      .miniTestView .content .contentTabs .buttonContainer {
        position: absolute;
        right: 15px;
        top: 3px; }
    .miniTestView .content .row {
      margin-left: 0;
      margin-right: 0;
      margin-top: 22px !important; }
      .miniTestView .content .row .elements {
        display: flex;
        justify-content: center; }
    .miniTestView .content h3 {
      font-family: 'Lato';
      color: #000000;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 1.3; }
    .miniTestView .content div.investStyle {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      box-shadow: 2px 2px 5px 0 #e6e6e6;
      border: solid 1px #e6e6e6;
      background-color: #FFFFFF;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .miniTestView .content div.investStyle p {
        margin-top: 3px;
        font-size: 16px;
        font-weight: bold;
        color: #1A3374;
        margin-bottom: 0px; }
    .miniTestView .content h4.investYears {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      box-shadow: 2px 2px 5px 0 #e6e6e6;
      border: solid 1px #e6e6e6;
      background-color: #FFFFFF;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center; }
    .miniTestView .content h4.typeContribution {
      text-align: left;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold; }
    .miniTestView .content h4.inputNumber {
      width: 125px;
      height: 50px;
      border-radius: 15px;
      box-shadow: 2px 2px 5px 0 #e6e6e6;
      border: solid 1px #e6e6e6;
      background-color: #FFFFFF;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 24px;
      margin: 0px auto;
      padding-right: 6px;
      font-weight: bold;
      float: right;
      /*
      * Currency
      */ }
      .miniTestView .content h4.inputNumber span {
        margin-left: 3px; }
    .miniTestView .content span.subtitle-text {
      color: #1A3374;
      letter-spacing: 0.2px;
      font-size: 14px; }
      .miniTestView .content span.subtitle-text-error {
        color: #FB4D6D; }
    .miniTestView .content .slider .fintupSlider .rangeslider-horizontal {
      height: 5px;
      background-color: #e6e6e6; }
  @media (max-width: 575.98px) {
    .miniTestView .content .miniTestTabs {
      width: 100%; }
    .miniTestView .multipleFormContainer .step-container {
      height: 100%; } }
  @media (min-width: 768px) {
    .miniTestView .content {
      width: 50%; } }
  @media (max-width: 1250px) {
    .miniTestView .content {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 900px) {
    .miniTestView .content .contentTabs .buttonContainer {
      position: relative;
      top: 0px;
      right: 0px;
      margin-top: 10px; } }
  .miniTestView .miniTestStepButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 20px;
    margin-left: 1%;
    margin-right: 1%; }
