@charset "UTF-8";
@font-face {
  font-family: fintupIcons;
  src: url("https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/fonts/fintupIcons20191202.woff"); }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.CircleIcon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #1A3374;
  display: flex;
  background-color: #1A3374;
  color: white;
  align-items: center;
  justify-content: center; }
  .CircleIcon--small {
    width: 20px;
    height: 20px;
    font-size: 17px; }
  .CircleIcon__icon {
    display: flex;
    flex: 1;
    border-radius: 100%;
    justify-content: center;
    align-items: center; }

.fintupIcons {
  font-family: fintupIcons; }
  .fintupIcons.okColor {
    color: #1A3374; }
  .fintupIcons.pensionPlanColor {
    color: #3F96A8; }
  .fintupIcons.savingColor {
    color: #7FB9C5; }
  .fintupIcons.cancelColor {
    color: #FB4D6D; }
  .fintupIcons.newColor {
    color: #52d2bc5f; }

.fintupIcons.icon-size-75 {
  font-size: 75px; }

.fintupIcons.icon-size-50 {
  font-size: 50px; }

.fintupIcons.icon-size-40 {
  font-size: 40px; }

.fintupIcons.icon-size-35 {
  font-size: 35px; }

.fintupIcons.icon-size-30 {
  font-size: 30px; }

.fintupIcons.icon-size-25 {
  font-size: 25px; }

.fintupIcons.icon-size-20 {
  font-size: 20px; }

.fintupIcons.icon-size-15 {
  font-size: 15px; }

.fintupIcons.icon-size-10 {
  font-size: 10px; }

.fintupIcons.icon-size-8 {
  font-size: 8px; }

.fintupIcons.icon-weight-500 {
  font-weight: 500; }

.fintupIcons.altavoz:before {
  content: ""; }

.fintupIcons.bell:before {
  content: ""; }

.fintupIcons.exit:before {
  content: ""; }

.fintupIcons.analyze:before {
  content: ""; }

.fintupIcons.academia:before {
  content: ""; }

.fintupIcons.check-done:before {
  content: ""; }

.fintupIcons.refresh:before {
  content: ""; }

.fintupIcons.test:before {
  content: ""; }

.fintupIcons.simulador:before {
  content: ""; }

.fintupIcons.contratar:before {
  content: ""; }

.fintupIcons.herramientas:before {
  content: ""; }

.fintupIcons.midinero:before {
  content: ""; }

.fintupIcons.miperfil:before {
  content: ""; }

.fintupIcons.retirar:before {
  content: ""; }

.fintupIcons.pensiones:before {
  content: ""; }

.fintupIcons.inversion:before {
  content: ""; }

.fintupIcons.info:before {
  content: ""; }

.fintupIcons.graphic:before {
  content: ""; }

.fintupIcons.graphicCircular:before {
  content: ""; }

.fintupIcons.aportar:before {
  content: ""; }

.fintupIcons.plus:before {
  content: ""; }

.fintupIcons.ahorro:before {
  content: ""; }

.fintupIcons.cancel:before {
  content: ""; }

.fintupIcons.check:before {
  content: ""; }

.fintupIcons.arrow-right:before {
  content: ""; }

.fintupIcons.arrow-left:before {
  content: ""; }

.fintupIcons.camera:before {
  content: ""; }

.fintupIcons.flecha-seleccionable-izquierda:before {
  content: ""; }

.fintupIcons.flecha-seleccionable-derecha:before {
  content: ""; }

.fintupIcons.flecha-seleccionable-arriba:before {
  content: ""; }

.fintupIcons.flecha-seleccionable-abajo:before {
  content: ""; }

.fintupIcons.euroDown:before {
  content: ""; }

.fintupIcons.mundo:before {
  content: ""; }

.fintupIcons.infinito:before {
  content: ""; }

.fintupIcons.brujula:before {
  content: ""; }

.fintupIcons.libro:before {
  content: ""; }

.fintupIcons.promediar:before {
  content: ""; }

.fintupIcons.actualidad:before {
  content: ""; }

.fintupIcons.articulo:before {
  content: ""; }

.fintupIcons.corazon:before {
  content: ""; }

.fintupIcons.filosofia:before {
  content: ""; }

.fintupIcons.play:before {
  content: ""; }

.fintupIcons.cloud:before {
  content: ""; }

.fintupIcons.phone:before {
  content: ""; }

.fintupIcons.phone2:before {
  content: ""; }

.fintupIcons.mail:before {
  content: ""; }

.fintupIcons.invite:before {
  content: ""; }

.fintupIcons.inviteplus:before {
  content: ""; }

.fintupIcons.copy:before {
  content: ""; }

.fintupIcons.circle:before {
  content: ""; }

.fintupIcons.circle-plus:before {
  content: ""; }

.fintupIcons.circle-minus:before {
  content: ""; }

.fintupIcons.pdf-icon:before {
  content: ""; }

.fintupIcons.jpg-icon:before {
  content: ""; }

.fintupIcons.png-icon:before {
  content: ""; }

.fintupIcons.dni-anverso:before {
  content: ""; }

.fintupIcons.dni-reverso:before {
  content: ""; }

.fintupIcons.file:before {
  content: ""; }

.fintupIcons.inversion-coloreado .path1:before {
  content: "\e90d";
  color: #1A3374; }

.fintupIcons.inversion-coloreado .path2:before {
  content: "\e90e";
  color: #fff;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path3:before {
  content: "\e90f";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path4:before {
  content: "\e910";
  color: #1A3374;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path5:before {
  content: "\e911";
  color: #1A3374;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path6:before {
  content: "\e912";
  color: #1A3374;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path7:before {
  content: "\e913";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path8:before {
  content: "\e914";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path9:before {
  content: "\e915";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path10:before {
  content: "\e916";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path11:before {
  content: "\e917";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path12:before {
  content: "\e918";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path13:before {
  content: "\e919";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path14:before {
  content: "\e91a";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path15:before {
  content: "\e91b";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path16:before {
  content: "\e91c";
  color: #000;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path1:before {
  content: "\e922";
  color: #000; }

.fintupIcons.ahorro-coloreado .path2:before {
  content: "\e923";
  color: #1A3374;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path3:before {
  content: "\e924";
  color: #fff;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path4:before {
  content: "\e925";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path5:before {
  content: "\e926";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path6:before {
  content: "\e927";
  color: #000;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path7:before {
  content: "\e928";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path8:before {
  content: "\e929";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path9:before {
  content: "\e92a";
  color: #3f96a8;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path10:before {
  content: "\e92b";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path11:before {
  content: "\e92c";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path12:before {
  content: "\e92d";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path13:before {
  content: "\e92e";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path14:before {
  content: "\e92f";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path15:before {
  content: "\e930";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path16:before {
  content: "\e931";
  color: #1A3374;
  margin-left: -1em; }
