//@import url('https://fonts.googleapis.com/css?family=Raleway');
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import '~react-notifications-component/dist/theme.css';
@import '../styles/_colors.scss';
@import '../styles/_helpers.scss';

#root {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
}

a {
  color: $action;
  font-weight: 600;
  transition: ease all .3s;
  

  &:hover{
    color: #000;
    text-decoration: none;

  }
}

a:focus {
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  text-decoration: none;
}

.newStyles.row {
  margin-left: 0px;
  margin-right: 0px;
}

.newStyles .row {
  margin-left: 0px;
  margin-right: 0px;
}

.newStyleGrid {
  padding-left: 0px;
  padding-right: 0px;
}

.fintup.fintupHeaderContainer.fintupPrivateContainer .appContent{
  padding-top: $desktop-header-size;
}

.fintup.fintupHeaderContainer.fintupPrivateContainer.hasOperationBar .appContent{
  padding-bottom: 40px;
}

.fintup.fintupHeaderContainer.oldForm .appContent{
  background-color: $dark;
}

/*Para alinear middle el contenido de la pantalla*/
.fintup .verticalContainer {
  display: table;
}

.fintup .verticalContainer > div {
  display: table-cell;
  vertical-align: middle;
}

.fintup.fintupHeaderContainer .appContent{
  min-height: calc(100vh - 72px);
  min-height: -o-calc(100vh - 72px); /* opera */
  min-height: -webkit-calc(100vh - 72px); /* google, safari */
  min-height: -moz-calc(100vh - 72px); /* firefox */
  background-color: $background-app;
}

.fintup.fintupHeaderContainer.fintupPrivateContainer .appContent{
  min-height: calc(100vh);
  min-height: -o-calc(100vh); /* opera */
  min-height: -webkit-calc(100vh); /* google, safari */
  min-height: -moz-calc(100vh); /* firefox */
  background-color: $background-app;
}

.fintup.fintupHeaderContainer .appContent .allHeight{
  min-height: calc(100vh - 120px);
  min-height: -o-calc(100vh - 120px);
  min-height: -webkit-calc(100vh - 120px); 
  min-height: -moz-calc(100vh - 120px); 
}

.vcenter{
  display: inline-block;
  vertical-align: middle;
  float: none;
}

//New Theme with blue header and footer and no-static position
.fintup.themeNew{

  .appContent{
    padding-bottom: 50px !important;
    overflow: auto;
  }
  
  position: relative;
  min-height: 100vh;

  .appContent{
    min-height: auto;
    padding-bottom: 50px;
  }
  @media (max-width: 768px) {
    .appContent{
      padding-bottom: 90px  !important;
    }
  }
}

.fintupResponsiveForm {
  background-color: $dark;
}

.container-fluid {
  padding: 0;
}

.fa-1_5x{
  font-size: 1.5em;
}

.fa-0_75x{
  font-size: 0.75em;
}

.font-weight-bold{
  font-weight: bold;
}

.font-style-italic{
  font-style: italic;
}

.no-padding-horizontal{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.bgGrey{
  background:#f5f5f5; 
}

.titleGrey {
  color:#7b7b7b;
}

.greyColor {
  color: #8a8a8a;
}

.borderTopRound {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  outline: 0;
}

body{
  color: #2d2d2d;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

.right {
  text-align: right;
}

.no-border {
  border-style: none!important;
  border-width: 0px!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}

@each $marginTopClass in $marginTopList {
  .marginTop#{$marginTopClass} {
    margin-top: #{$marginTopClass}px;
  }
}

@each $marginTopClass in $marginTopImportantList {
  .marginTopImportant#{$marginTopClass} {
    margin-top: #{$marginTopClass}px !important;
  }
}

@each $marginBottomClass in $marginBottomList {
  .marginBottom#{$marginBottomClass},
  .marginBottom#{$marginBottomClass} {
    margin-bottom: #{$marginBottomClass}px;
  }
}

@each $element in $marginRightList {
  .marginRight#{$element} {
    margin-right: #{$element}px;
  }
}

@each $element in $marginLeftList {
  .marginLeft#{$element} {
    margin-left: #{$element}px;
  }
}

@each $element in $paddingLeftList{
  .padding#{$element} {
    padding: #{$element}px;
  }
}

@each $element in $paddingLeftList {
  .paddingLeft#{$element} {
    padding-left: #{$element}px;
  }
}

@each $element in $paddingRightList {
  .paddingRight#{$element} {
    padding-right: #{$element}px;
  }
}

@each $element in $paddingTopList {
  .paddingTop#{$element} {
    padding-top: #{$element}px !important;
  }
}

.imageStack {
  position: relative;
}

.imageStack .imageBackgroung{
  position: absolute;
}

.imageStack .mainImage{
  position: absolute;
}

.clearAll{
	clear: both;
}

.fRight{
  float: right;
}

.fLeft{
  float: left;
}

.flex-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.flex-wrapper-start {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-item {
  width: 100%;
}

.flex-item-inherit {
  width: inherit;
}

.fintup .formColumn {
  padding-right: 0px;
}

.flexHorizontalCenter {
  display: flex; /* establish flex container */
  flex-direction: row; /* make main axis horizontal (default value) */
  justify-content: center; /* center items horizontally, in this case */
  align-items: center; /* center items vertically, in this case */
}

.fintupStrong {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  box-shadow: inset 0 -5px 0 0px #f363;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fintupStrongGreen {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, rgba(233, 253, 240, 1), rgba(233, 253, 240, 1));
  -webkit-transition: .5s background-image linear;
  transition: .5s background-image linear;
  cursor: pointer;
}

.fintupStrongGreen:active, .fintupStrongGreen:focus, .fintupStrongGreen:hover {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, rgba(125, 255, 179, 1), rgba(125, 255, 179, 1));
  -webkit-transition: .5s background-image linear;
  transition: .5s background-image linear;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.viewContent {
  padding-left: 15px;
  padding-right: 15px; 
 }

 .rowListDetail {
   border-style: solid;
   border-width: 1px;
   border-color: #8a8a8a;
   margin-left: 0px;
   margin-bottom: 15px;
   color: #8a8a8a;
 }

 .rowListDetailLabel {
   font-weight: 700;
   color: #8a8a8a;
 }

 .rowListDetail .rowListDetailTitle {
   font-weight: 700;
 }

 .noWrap {
  white-space: nowrap;
 }

 .pensionPlanColor {
  color: $medium;
 }

 .savingColor {
  color: $light;
 }

 .visibility-hidden{
    visibility: hidden;
  }

  .display-none{
    display: none;
  }
  .margin-auto{
    margin: auto;
  }

  .text-large{
    font-size: $font-size-large !important;
  }

@media (min-width: 767px) {
  .show-xs{
    display: none!important;
  }

  .tableView {
    display: table;
    width: 100%;
  }
  
  .tableView > div {
    display: table-row;
  }
  
  .tableView > div > div {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
}

/* Extra small devices (767px and down) */
@media (max-width: 767px) {

  #root {
    font-family: 'Lato', sans-serif;
    font-size: 11px;
  }

  .fintup{
    &.fintupFullWidget {
      .controlIframePadding {
        padding-right: 65px!important;
        padding-left: 65px!important;
      }
    }
  }

  .fintup.fintupHeaderContainer.fintupPrivateContainer.hasOperationBar .appContent{
    padding-bottom: 20px;
    padding-top: $mobile-header-size;
  }

  .fintup.fintupHeaderContainer.fintupPublicContainer .appContent{
    min-height: calc(100vh - 100px);
    min-height: -o-calc(100vh - 100px); /* opera */
    min-height: -webkit-calc(100vh - 100px); /* google, safari */
    min-height: -moz-calc(100vh - 100px); /* firefox */
    background-color: #FFFFFF;
  }

  .fintup.fintupHeaderContainer.fintupPublicContainer.cnp-app .appContent{
    min-height: calc(100vh - 160px);
    min-height: -o-calc(100vh - 160px); /* opera */
    min-height: -webkit-calc(100vh - 160px); /* google, safari */
    min-height: -moz-calc(100vh - 160px); /* firefox */
    background-color: #FFFFFF;
  }

  .fintup.fintupHeaderContainer .appContent .allHeight{
    min-height: calc(100vh - 160px);
    min-height: -o-calc(100vh - 160px);
    min-height: -webkit-calc(100vh - 160px); 
    min-height: -moz-calc(100vh - 160px); 
  }

  .fintup.fintupHeaderContainer .appContent{
    min-height: calc(100vh - 110px);
    min-height: -o-calc(100vh - 110px); /* opera */
    min-height: -webkit-calc(100vh - 110px); /* google, safari */
    min-height: -moz-calc(100vh - 110px); /* firefox */
  }

    .row.vertical-align {
        display: block; /* Turn off the flexible box layout */
    }

    .paddingBottom0-xs {
      padding-bottom: 0px!important;
    }

    .paddinLeft15-xs {
      padding-left: 25px;
    }

    .marginBottom15-xs {
      margin-bottom: 15px;
    }

    .marginBottom10-xs {
      margin-bottom: 10px;
    }

    .marginTop5-xs {
      margin-top: 5px;
    }

    .marginTop10-xs {
      margin-top: 10px;
    }

    .marginTop12-xs {
      margin-top: 12px;
    }

    .marginTop15-xs {
      margin-top: 15px;
    }

    .marginTop20-xs {
      margin-top: 20px;
    }

    .fintupResponsiveForm {
      background-color: #FFFFFF;
    }


    .tableView.mobile {
      display: table;
      width: 100%;
    }
    
    .tableView.mobile > div {
      display: table-row;
    }
    
    .tableView.mobile > div > div {
      display: table-cell;
      float: none;
      vertical-align: middle;
    }

    .newStyleGridMobile,
    .newStyleGridMobile > div, /*Para los row*/
    .newStyleGridMobile > div > div /*Para los col*/
    {
      padding-left: 0px;
      padding-right: 0px;
    }

    .text-left-mobile {
      text-align: left;
    }

}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .row.vertical-align {
        display: flex;
        align-items: center;
    }

    .row.flex-row {
      display: flex;
    }
}


:focus {
  outline: -webkit-focus-ring-color auto 0px;
}



//flex helpers

.flex{
  display: flex;
}

.flex-one{
  flex: 1;
}

.flex-zero{
  flex: 0;
}

.space-between{
  justify-content: space-between;
}

.flex-start{
  justify-content: flex-start;
}

.flex-end{
  justify-content: flex-end;
}

.flex-center{
  justify-content: center;
}

.flex-column{
  flex-direction: column;
}

/*
* Notifications
*/

.notification-item{

  .notification-close{
    &:after{
      font-size: $font-size-large !important;
    }
  }
  
  //Specific colors

  &.notification-danger{
    border-left: none;
    background-color: $cancel;

    .notification-close{
      background-color: $cancel;
    }
  }

  &.notification-success{
    border-left: none;
    background-color: $action;

    .notification-close{
      background-color: $action;
    }
  }
}

.card-item{  
        //max-width: 400px;
        border-radius: 20px;
        box-shadow: 2px 2px 5px 0 $shadow-color-default;
        border: solid 1px $shadow-color-default;
        background-color: $dark-color-font;
        /*display: flex;
        justify-content: center;
        align-items: center;*/
        margin: 15px;
        //margin-bottom: 25px;
        padding: 30px 20px 15px;
       

        .card-header{
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          
          .title-card{
            font-size: $font-size-mobile-extrabig;
            font-weight: bold;
            margin-bottom: 0px;
          }

          .subtitle-card{
            margin-bottom: 0px;
          }

        }

        .card-content{
          padding-right: 10px;
          padding-left: 10px;
          
          .element-list{
             list-style-position: outside;
             list-style: none;
             padding-left: 0px;
             margin-bottom: 0px;

            li{
             
              padding-top: 10px;
              //padding-bottom: 10px;
              font-weight: 600;
              padding-left: 22px;
            }
            li::before{
              content: "\2022";
              color: $action;
              font-weight: bold;
              display: inline-block; 
              font-size: $font-size-extrabig;
              width: 1em;
              margin-left: -1em;
            }
          }

          .element-indicator{
            display: flex;
            justify-content: space-around;
            align-items: center;
            background-color: $background-element;
            padding: 15px;
            margin-top: 5px;

            div{
              //width: 50%;
              display: flex;
              justify-content: space-between;
              //align-items: center;
              flex-direction: column;
              p{
                margin-bottom: 0px;
              }
              p:first-child{
                font-weight: bold;
              }

              .fintupIndicatorText{
                &.ok{
                  &.arrow:before{
                    content:url('/img/arrow-up-white-small.svg');
                  }
                }
                &.no-ok{
                  &.arrow:before{
                    content:url('/img/arrow-down-white-small.svg');
                  }
                }
              }
            }            
          }

          .element-graphic{
            margin-top: 30px;
            margin-bottom: 10px;
            .element-graphic-header{
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 10px;
              .switch{
                display: flex;
                //border: 1px solid;{
                cursor: pointer;

                div{
                  border: 1px solid $grey-font;
                  box-sizing: border-box;
                  padding: 5px;
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-size: 16px 16px;
                  height: 30px;
                  width: 30px;
                }

                div.active{
                  background-color: $result;
                  border-color: $medium;
                }

                //Left
                div:first-child{
                  border-top-left-radius: 100px;
                  border-bottom-left-radius: 100px;
                  background-image: url("/img/icono_ahorro.svg");
                  &.active{
                    background-image: url("/img/icono_ahorro_active.svg");
                  }
                }

                //Right
                div:last-child{
                  border-top-right-radius: 100px;
                  border-bottom-right-radius: 100px;
                  //border-left: 0px;
                  background-image: url("/img/icono_inversion.svg");

                  &.active{
                    background-image: url("/img/icono_inversion_active.svg");
                  }
                }


              }
              p{
                font-size: $font-size-mobile-large;
                font-weight: bold;
                margin-bottom: 0px;
              }
            }
          }

           //Button
          .buttonNewSave{
            display: flex;
            justify-content: center;
            margin-top: 15px;
            width: 100%;
          }

          .slider{
            //padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px dashed $border-element;
          }
        
        }
      }

//Cards and evolution modal portfolio
.item-element{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed $border-element;
  font-size: $font-size-medium;

  p{
    margin-bottom: 0px;
  }

  p:first-child{
    max-width: 70%;
  }

  p:last-child{
    font-weight: bold;
  }

  &.border-bottom-solid{
    border-bottom: 2px solid $border-element;
  }
}   

//New buttons (cards and other components)
.item-buttons{
  display: flex;
  .buttonOpenModal{
    display: flex;
    width: 100%;
    //color: $cancel;
    font-weight: bold;
    font-size: 20px;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;

    img{
      cursor: pointer;
    }

    &:nth-child(2){
      margin-left: 15px;
    }
  }
}

//Popover menu product (global position)
.menu-product{
  margin-top: 5px;
  ul{
    min-width: 180px;
    position: absolute;
    right: 50%;
    background: $dark;
    box-shadow: 2px 2px 8px rgba($result-color-font, 0.2);
    border-radius: 4px;
    //padding: 0px;
    list-style: none;
    padding: 10px 20px;
    white-space: nowrap;

    li{
      cursor: pointer;
      margin: 5px;
      padding: 5px 0px;
      border-bottom: 1px dashed rgba($dark-color-font, 0.2);
      font-weight: bold;
      color: $dark-color-font;
      display: flex;
      align-items: center;
      font-size: $font-size-mobile-medium;

      span{
        margin-top: 3px;
      }

      img{
        margin-right: 10px;
      }

      &:last-child{
        border-bottom: 0px;
      }
    }
  }
}